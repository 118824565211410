.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.sidebar-left {
    border-right: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 .125rem 9.375rem rgba(90, 97, 105, .1), 0 .25rem .5rem rgba(90, 97, 105, .12), 0 .9375rem 1.375rem rgba(90, 97, 105, .1), 0 .4375rem 2.1875rem rgba(165, 182, 201, .1);
}

.sidebar-left .nav-link .nav-icon {
    margin-right: .5rem;
}

.sidebar-right {
    border-left: 1px solid rgba(0, 0, 0, .1);
}

.bd-header {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.content-wrapper {
    margin-left: .5rem;
}

.content {
    background-color: white;
}

.icon {
    justify-content: center;
    align-items: center;
}

button.nav-link.btn.btn-link {
    text-align: left
}

html body div#root div.container-fluid div.row div.sidebar-left.col-xl-1.col-md-1 div.flex-column.nav button.nav-link.btn.btn-link {
    margin: 2%;
    padding: 0;
}

html body div#root div.container-fluid div.row div.col div.row nav.nav.nav-tabs button.btn.btn-link.btn-sm {
    margin-top: -0.6rem;
    padding-left: 0.6rem;
}

html body div#root div.container-fluid div.bd-header.row div.col div.form-group div.input-group.input-group-lg div.rbt {
    display: flex;
    flex-direction: row;
    width: 65%;
}

html body div#root div.container-fluid div.bd-header.row div.col div.form-group div.input-group.input-group-lg div.rbt div input.rbt-input-main.form-control.rbt-input.form-control-lg {
    border-radius: 0;
}

.form-label {
    font-size: small;
}