body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(243, 244, 246);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.table td,
.table th {
    padding: 0.125rem;
}

.orgchart.myChart {
    background-image: linear-gradient(90deg,
            rgba(33, 90, 136, 0.15) 10%,
            rgba(0, 0, 0, 0) 10%),
        linear-gradient(rgba(33, 90, 136, 0.15) 10%, rgba(0, 0, 0, 0) 10%) !important;
}

.orgchart.myChart>ul>li>ul li::before {
    border-top-color: #215a88;
}

.orgchart.myChart>ul>li>ul li .oc-node::before,
.orgchart.myChart ul li .oc-node:not(:only-child)::after {
    background-color: #215a88;
}

.orgchart.myChart .oc-node .oc-heading {
    box-sizing: border-box;
    background-color: #215a88;
    color: #fff;
    width: 130px;
    padding: 2px;
}

.orgchart.myChart .oc-node .oc-content {
    box-sizing: border-box;
    color: #215a88;
    background-color: #fff;
    width: 130px;
    height: 65px;
    padding: 2px;
    border: 1px solid #215a88;
}

.orgchart.myChart {
    background-image: linear-gradient(90deg,
            rgba(33, 90, 136, 0.15) 10%,
            rgba(0, 0, 0, 0) 10%),
        linear-gradient(rgba(33, 90, 136, 0.15) 10%, rgba(0, 0, 0, 0) 10%) !important;
}

.orgchart.myChart>ul>li>ul li::before {
    border-top-color: #215a88;
}

.orgchart.myChart>ul>li>ul li .oc-node::before,
.orgchart.myChart ul li .oc-node:not(:only-child)::after {
    background-color: #215a88;
}

.orgchart.myChart .oc-node .position {
    box-sizing: border-box;
    background-color: #215a88;
    color: #fff;
    width: 130px;
    height: 130px;
    padding: 2px;
}

.orgchart.myChart .oc-node .fullname {
    box-sizing: border-box;
    color: #215a88;
    background-color: #fff;
    width: 130px;
    height: 65px;
    padding: 2px;
    border: 1px solid #215a88;
}